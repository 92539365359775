import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Paper,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { NGROK } from "../../../APIs";
import { myLocalStorage } from "../../../components/StorageHelper";
import useUserStore from "../../../services/userStore";
import { makeStyles } from "@mui/styles";
import { awsIcons } from "../AWS/AWSIcons";

const useStyles = makeStyles({
  root: {
    maxHeight: 750,
    border: "1px solid",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
  row: {
    "& > td": {
      padding: "30px",
    },
  },
});
const AzureRoles = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoleActions, setSelectedRoleActions] = useState([]);
  const latestTenant = myLocalStorage.getItem("latestTenant")?.tenantName;
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantList, setTenantList] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [roles, setRoles] = useState([]);
  const [filter, setFilter] = useState("all");
  const [roleAction, setRoleAction] = useState("actions");
  const classes = useStyles();

  const userData = useUserStore((state) => state.user);

  const ondemandButton = async () => {
    setIsSyncing(true);
    const response = await axios.get(`${NGROK}/api/azure-sync/onDemand`, {
      params: {
        tenantName: selectedTenantName,
      },
    });
    setIsSyncing(false);
  };

  const handlePublishToggle = async (event, azureId) => {
    const published = event.target.checked;
    setRoles((prevData) =>
      prevData.map((role) =>
        role.azureId === azureId ? { ...role, published } : role,
      ),
    );

    let requestData = {
      resourceId: azureId,
      wsTenantName: selectedTenantName,
      flag: event.target.checked,
      type: "ROLE_DEFINITION",
    };

    const response = await axios.patch(
      `${NGROK}/api/azureResources/v1/publish`,
      requestData,
    );
  };

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
      myLocalStorage.setItem("latestTenant", tenant);
    }
  };

  const getTheSelectedRoleDefinition = async (selectedRole) => {
    const response = await axios.get(
      `${NGROK}/api/azureResources/v1/getAzureRoleDefinitionById?id=${selectedRole.id}&tenantName=${latestTenant}`,
    );
    if (response.status === 200) {
      if (response?.data) setSelectedRoleActions([response.data]);
      else setSelectedRoleActions([]);
    } else setSelectedRoleActions([]);
  };

  const getAllRoles = async () => {
    const response = await axios.get(
      `${NGROK}/api/azureResources/v1/getRoleDefinitionsName?tenantName=${latestTenant}`,
    );
    if (response.status === 200) return response.data;
    else return [];
  };

  const fetchData = async (initialLoad = false) => {
    try {
      const [roles] = await Promise.all([getAllRoles()]);
      setRoles(roles);
      setLoading(false);
      if (initialLoad) {
        const filteredRoles = filterRoles(roles, filter);
        let selectRole = filteredRoles.length > 0 ? filteredRoles[0] : null;
        setSelectedRole(selectRole);
        if (selectRole) getTheSelectedRoleDefinition(selectRole);
      }
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  const handleFilterChange = (event, newFilter) => {
    const filteredRoles = filterRoles(roles, newFilter);
    setFilter(newFilter);
  };

  const handleRoleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleRoleClick = (role) => {
    setSelectedRole(role);
    getTheSelectedRoleDefinition(role);
  };

  const filterRoles = (roles, filter) => {
    if (filter === "default") {
      return roles?.filter((role) => role.roleType === "BuiltInRole");
    } else if (filter === "custom") {
      return roles?.filter((role) => role.roleType !== "BuiltInRole");
    }
    return roles;
  };

  const filteredRoles = filterRoles(
    roles?.filter(
      (role) =>
        role?.roleName &&
        role?.roleName.toLowerCase().includes(search.toLowerCase()),
    ),
    filter,
  );

  useEffect(() => {
    fetchData(true);
  }, [latestTenant]);

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  return (
    <Box>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontSize: "22px",
        }}
      >
        Azure Roles
      </Typography>
      <Box
        display="flex"
        mt={5}
        mb={5}
        sx={{ width: "100%", justifyContent: "space-between" }}
      >
        <Stack direction={"row"} spacing={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {tenantList?.length > 0 ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={selectedTenantName ? selectedTenantName : " "}
                options={tenantList.map((tenant) => tenant.tenantName)}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Tenant List" />
                )}
                onChange={(e, value) => {
                  handleTenantChange(value);
                }}
              />
            ) : null}
          </Box>
        </Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{ ml: "auto" }}
            disabled={isSyncing}
            onClick={ondemandButton}
          >
            {isSyncing ? "Syncing" : "Sync"}
          </Button>
        </Box>
      </Box>
      {loading ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Stack spacing={5} mt={2}>
          <Box display="flex" justifyContent="space-between" sx={{ gap: 2 }}>
            <ToggleButtonGroup
              value={filter}
              exclusive
              onChange={handleFilterChange}
              aria-label="filter toggle buttons"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "auto",
              }}
            >
              <ToggleButton
                value="all"
                sx={{
                  fontSize: "18px",
                }}
              >
                All
              </ToggleButton>
              <ToggleButton
                value="default"
                sx={{
                  fontSize: "18px",
                }}
              >
                Default
              </ToggleButton>
              <ToggleButton
                value="custom"
                sx={{
                  fontSize: "18px",
                }}
              >
                Custom
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Stack direction="row" spacing={5}>
            <Box
              sx={{
                width: "38%",
                minWidth: "320px",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  overflow: "auto",
                  height: "100%",
                }}
                className={classes.root}
              >
                <Table
                  sx={{
                    width: "100%",
                    "& th": {
                      background: "#233044",
                      color: "#fff",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    },
                    "& td, & th": {
                      borderBottom: "1px solid #233044",
                      fontSize: "18px",
                    },
                  }}
                  size="large"
                  aria-label="sticky table"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Roles Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        position: "sticky",
                        top: "54px",
                        backgroundColor: "#f5f5f5",
                        zIndex: 1,
                      }}
                    >
                      <TableCell>
                        <TextField
                          label="Search Roles"
                          variant="outlined"
                          value={search}
                          onChange={handleRoleSearchChange}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    {filteredRoles.length > 0 ? (
                      filteredRoles.map((role) => (
                        <TableRow
                          key={role.id}
                          onClick={() => handleRoleClick(role)}
                          selected={role.id === selectedRole?.id}
                          sx={{
                            "&:hover": {
                              background: "#f5f5f5",
                              cursor: "pointer",
                            },
                            "& td, & th": {
                              borderBottom: "1px solid #233044",
                              fontSize: "18px",
                            },
                            "&.Mui-selected": {
                              background: "#233044 !important",
                              "& td, & th": {
                                color: "#fff",
                              },
                            },
                          }}
                        >
                          <TableCell>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent={"space-between"}
                            >
                              <Box display="flex" alignItems="center">
                                {awsIcons.roles}
                                <Box ml={2}>
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      fontSize: "18px",
                                    }}
                                  >
                                    {role.roleName}
                                  </Typography>
                                </Box>
                              </Box>

                              <Box ml={2}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={role?.published}
                                      name="publishSwitch"
                                      onChange={(e) =>
                                        handlePublishToggle(e, role.azureId)
                                      }
                                    />
                                  }
                                  label="Publish"
                                />
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="h6">No Roles Found</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box width="35%">
              {selectedRole && selectedRoleActions.length > 0 && (
                <Box>
                  <Typography
                    variant="h6"
                    sx={{
                      backgroundColor: "#233044",
                      color: "#fff",
                      padding: "10px",
                      fontSize: "18px",
                      borderRadius: "5px",
                    }}
                  >
                    Role Details
                  </Typography>
                  <Tabs
                    value={roleAction}
                    onChange={(event, newValue) => setRoleAction(newValue)}
                  >
                    <Tab label="Actions" value="actions" />
                    <Tab label="Not Actions" value="notActions" />
                  </Tabs>
                  {roleAction === "actions" && (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          {selectedRoleActions[0]?.actions?.length > 0 ? (
                            selectedRoleActions[0]?.actions?.map(
                              (action, index) => (
                                <TableRow key={index}>
                                  <TableCell>{action}</TableCell>
                                </TableRow>
                              ),
                            )
                          ) : (
                            <TableRow>
                              <TableCell>No actions available</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  {roleAction === "notActions" && (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          {selectedRoleActions[0]?.notActions?.length > 0 ? (
                            selectedRoleActions[0]?.notActions?.map(
                              (notAction, index) => (
                                <TableRow key={index}>
                                  <TableCell>{notAction}</TableCell>
                                </TableRow>
                              ),
                            )
                          ) : (
                            <TableRow>
                              <TableCell>No actions available</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Box>
              )}
            </Box>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default AzureRoles;
