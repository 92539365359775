import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  Stack,
  CircularProgress,
  TextField,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import moment from "moment";
import { awsIcons } from "../AWSIcons";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    maxHeight: 550,
    border: "1px solid",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
  row: {
    "& > td": {
      padding: "30px",
    },
  },
});

const S3Buckets = ({ s3Datas, setS3Datas, tenantName }) => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedS3Bucket, setSelectedS3Bucket] = useState("");
  const classes = useStyles();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectedS3 = (id) => {
    setSelectedS3Bucket(id);
  };

  const filteredbucketDatas = s3Datas?.filter((db) =>
    db?.bucketName.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const filteredData = s3Datas.filter((db) => db.id === selectedS3Bucket);

  const fetchS3Buckets = async (isFirst = false) => {
    if (isFirst) {
      setLoading(true);
    }
    if (tenantName) {
      const response = await axios.get(
        `${NGROK}/api/aws/getAllS3Buckets?tenantName=${tenantName}`,
      );
      setS3Datas(response?.data);
    }
    setLoading(false);
  };

  const updateS3Manage = async (bucketName) => {
    if (tenantName) {
      const response = await axios.put(
        `${NGROK}/api/aws/publishBucket?tenantName=${tenantName}&bucketName=${bucketName}`,
      );
      fetchS3Buckets();
    }
  };

  useEffect(() => {
    fetchS3Buckets(true);
    const interval = setInterval(fetchS3Buckets, 5000);
    return () => clearInterval(interval);
  }, [tenantName]);

  return (
    <Stack direction={"row"} spacing={5}>
      <Box width={"40%"}>
        <TableContainer
          component={Paper}
          sx={{
            overflow: "auto",
            height: "100%",
          }}
          className={classes.root}
        >
          <Table
            sx={{
              height: "fit-content",
              "& th": {
                background: "#233044",
                color: "#fff",
                position: "sticky",
                top: 0,
                zIndex: 1,
              },
              "& td, & th": {
                borderBottom: "1px solid #233044",
                fontSize: "18px",
              },
            }}
            size="large"
            aria-label="sticky table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell>Bucket Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : s3Datas.length > 0 ? (
                <>
                  <TableRow
                    sx={{
                      position: "sticky",
                      top: "54px",
                      backgroundColor: "#f5f5f5",
                      zIndex: 1,
                    }}
                  >
                    <TableCell colSpan={7}>
                      <TextField
                        variant="outlined"
                        placeholder="Search Buckets"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        fullWidth
                      />
                    </TableCell>
                  </TableRow>
                  {filteredbucketDatas.map((bucket, index) => (
                    <TableRow
                      key={index}
                      selected={bucket.id === selectedS3Bucket}
                      onClick={(event) => {
                        if (
                          event.target.closest("td") &&
                          !event.target.closest("div.MuiSwitch-root")
                        ) {
                          selectedS3(bucket.id);
                        }
                      }}
                      sx={{
                        "&:hover": {
                          background: "#f5f5f5",
                          cursor: "pointer",
                        },
                        "&.Mui-selected": {
                          background: "#233044 !important",
                          "& td, & th": {
                            color: "#fff",
                          },
                        },
                        "& td, & th": {
                          borderBottom: "1px solid #233044",
                          fontSize: "18px",
                        },
                      }}
                    >
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          {awsIcons.buckets}
                          <Box ml={2}>
                            <Typography variant="h6" sx={{ fontSize: 18 }}>
                              {bucket.bucketName}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Switch
                            checked={bucket.isManagedS3}
                            onChange={() => {
                              updateS3Manage(bucket.bucketName);
                            }}
                          />
                          <Typography variant="body2" sx={{ fontSize: 16 }}>
                            Publish
                          </Typography>{" "}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography align="center">No S3 Buckets Found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {selectedS3Bucket && filteredData ? (
        <>
          <Box
            width={"50%"}
            sx={{
              border: "1px solid",
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                background: "#233044",
                color: "#fff",
                padding: "10px",
                borderRadius: "5px",
                fontSize: "18px",
                p: 4.8,
              }}
            >
              Details of {filteredData[0]?.bucketName}
            </Typography>
            <List>
              {filteredData.length > 0
                ? filteredData.map((bucket, index) => (
                    <div key={index}>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: 18 }}>
                              <strong>Bucket Name</strong>
                            </Typography>
                          }
                          secondary={
                            <Typography sx={{ fontSize: 18 }}>
                              {bucket.bucketName || "-"}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: 18 }}>
                              <strong>Region</strong>
                            </Typography>
                          }
                          secondary={
                            <Typography sx={{ fontSize: 18 }}>
                              {bucket.region || "-"}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontSize: 18 }}>
                              <strong>Creation Date</strong>
                            </Typography>
                          }
                          secondary={
                            <Typography sx={{ fontSize: 18 }}>
                              {moment(bucket.creationDate).format(
                                "YYYY-MM-DD HH:mm",
                              ) || "-"}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </div>
                  ))
                : null}
            </List>
          </Box>
        </>
      ) : null}
    </Stack>
  );
};

export default S3Buckets;
