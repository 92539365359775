import React, { useEffect, useState } from "react";
import { NGROK } from "../../../../APIs";
import axios from "axios";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import moment from "moment";
import { awsIcons } from "../../AWS/AWSIcons";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    maxHeight: 550,
    border: "1px solid",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
  row: {
    "& > td": {
      padding: "30px",
    },
  },
});
const AzureDatabase = ({ tenantName }) => {
  const [loading, setLoading] = useState(false);
  const [dbs, setDbs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDBId, setSelectedDBId] = useState("");
  const classes = useStyles();

  const handlePublishToggle = async (event, databaseName, id) => {
    const published = event.target.checked;
    setDbs((prevData) =>
      prevData.map((db) =>
        db.databaseName === databaseName ? { ...db, published } : db,
      ),
    );

    let requestData = {
      resourceId: databaseName,
      wsTenantName: tenantName,
      flag: event.target.checked,
      type: "DATABASE",
    };

    const response = await axios.patch(
      `${NGROK}/api/azureResources/v1/publish`,
      requestData,
    );
  };

  const getFormattedSize = (sizeInBytes) => {
    if (sizeInBytes >= 1024 * 1024 * 1024) {
      return (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    } else if (sizeInBytes >= 1024 * 1024) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
    } else if (sizeInBytes >= 1024) {
      return (sizeInBytes / 1024).toFixed(2) + " KB";
    } else {
      return sizeInBytes + " bytes";
    }
  };

  const selectedDb = (dbId) => {
    setSelectedDBId(dbId);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const getIcons = (engine) => {
    const engineLower = engine.toLowerCase();

    switch (true) {
      case engineLower.includes("mysql"):
        return awsIcons.mysql;
      case engineLower.includes("sql"):
        return awsIcons.sqlServer;
      case engineLower.includes("postgres"):
        return awsIcons.psql;
      case engineLower.includes("oracle"):
        return awsIcons.oracle;
      case engineLower.includes("maria"):
        return awsIcons.maria;
      default:
        return awsIcons.oracle;
    }
  };

  const fetchDbDatas = async (isFirst = false, tenantName) => {
    if (isFirst) setLoading(true);
    try {
      const response = await axios.get(
        `${NGROK}/api/azureResources/v1/all?type=DATABASE&tenantName=${tenantName}`,
      );
      if (response?.data) {
        const uniqueDatabases = response?.data.flatMap((server) =>
          server.azureDatabases.reduce((acc, database) => {
            if (
              !acc.find((db) => db.azureDatabaseId === database.azureDatabaseId)
            ) {
              acc.push(database);
            }
            return acc;
          }, []),
        );
        setDbs(uniqueDatabases);
      }
    } catch (e) {
      setDbs([]);
    }

    setLoading(false);
  };
  useEffect(() => {
    if (tenantName != null) fetchDbDatas(true, tenantName);
  }, [tenantName]);

  const filtereddbDatas = dbs?.filter((db) =>
    db?.databaseName.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const filteredData = dbs.filter((db) => db.azureDatabaseId === selectedDBId);

  return (
    <>
      <Stack direction={"row"} spacing={5}>
        <Box width={"40%"}>
          <TableContainer
            component={Paper}
            sx={{
              overflow: "auto",
              height: "100%",
            }}
            className={classes.root}
          >
            <Table
              sx={{
                height: "fit-content",
                "& th": {
                  background: "#233044",
                  color: "#fff",
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                },
                "& td, & th": {
                  borderBottom: "1px solid #233044",
                  fontSize: "18px",
                },
              }}
              size="large"
              aria-label="sticky table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7} align="left">
                    Databases
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : dbs.length > 0 ? (
                  <>
                    <TableRow
                      sx={{
                        position: "sticky",
                        top: "54px",
                        backgroundColor: "#f5f5f5",
                        zIndex: 1,
                      }}
                    >
                      <TableCell colSpan={7}>
                        <TextField
                          variant="outlined"
                          placeholder="Search Databases"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          fullWidth
                        />
                      </TableCell>
                    </TableRow>
                    {filtereddbDatas.map((dbData, index) => {
                      return (
                        <TableRow
                          key={index}
                          selected={dbData.azureDatabaseId === selectedDBId}
                          onClick={() => {
                            selectedDb(dbData.azureDatabaseId);
                          }}
                          sx={{
                            "&:hover": {
                              background: "#f5f5f5",
                              cursor: "pointer",
                            },
                            "&.Mui-selected": {
                              background: "#233044 !important",
                              "& td, & th": {
                                color: "#fff",
                              },
                            },
                            "& td, & th": {
                              borderBottom: "1px solid #233044",
                              fontSize: "18px",
                            },
                          }}
                        >
                          <TableCell>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent={"space-between"}
                            >
                              <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="flex-start"
                              >
                                <div>{getIcons(dbData.databaseType)}</div>

                                <Box ml={2}>
                                  <Typography
                                    variant="h6"
                                    sx={{ fontSize: "18px" }}
                                  >
                                    {dbData.databaseName}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box ml={2}>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={dbData.published}
                                      onChange={(e) =>
                                        handlePublishToggle(
                                          e,
                                          dbData.databaseName,
                                          dbData.id,
                                        )
                                      }
                                      name="publishSwitch"
                                    />
                                  }
                                  label="Publish"
                                />
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      <Typography align="center">No Databases Found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {selectedDBId && filteredData ? (
          <>
            <Box
              width={"50%"}
              sx={{
                border: "1px solid",
              }}
            >
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  background: "#233044",
                  color: "#fff",
                  padding: "10px",
                  borderRadius: "5px",
                  fontSize: "18px",
                  p: 4.8,
                }}
              >
                Details of {filteredData[0]?.databaseName}
              </Typography>
              <List>
                {filteredData.length > 0 ? (
                  filteredData.map((db, index) => {
                    const dbSize = getFormattedSize(db.maxSizeBytes);

                    return (
                      <div key={index}>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Database Name</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.databaseName || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Database Type</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.databaseType || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Database Id</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.azureDatabaseId || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Status</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.dbStatus || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Secondary location</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.defaultSecondaryLocation || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Edition</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.edition || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Status</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.status || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Region</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.region || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Version</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {db.version || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Size</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {dbSize || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>

                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: 18 }}>
                                <strong>Created At</strong>
                              </Typography>
                            }
                            secondary={
                              <Typography sx={{ fontSize: 18 }}>
                                {moment(db.createdDate).format(
                                  "YYYY-MM-DD HH:mm",
                                ) || "-"}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </div>
                    );
                  })
                ) : (
                  <Typography variant="body1"> No details available</Typography>
                )}
              </List>
            </Box>
          </>
        ) : null}
      </Stack>
    </>
  );
};

export default AzureDatabase;
